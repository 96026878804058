import { LanguageEvents } from '../core/constants/languages';
import { EventBus } from '../core/events';
import { getExtensionPoint } from '../core/extensionPoints';
import { isSupportedBrowser } from '../utils/browsers';
import { PlatformEvents } from './constants/events';
import PlatformRoutes from './routes';
import { NAMESPACE as APPLICATIONS_NAMESPACE, SET_LOADING_MODULES } from './store/applications';

const platformExtension = getExtensionPoint('platform');

/**
 * Starts up the platform application
 */
async function start() {
  try {
    EventBus.$emit(PlatformEvents.APP_STARTING);

    // Wait for the initial navigation. This is needed to manipulate the current route or navigate to other routes
    await new Promise((resolve, reject) => {
      platformExtension.router.onReady(resolve, reject);
    });

    // Check if the browser is supported.
    if (!isSupportedBrowser()) {
      await platformExtension.router.push({ name: 'browserVersionError' });
      return;
    }

    // Init languages on app start
    await platformExtension.services.getService('languages').initializeLanguages();
    platformExtension.services.getService('languages').updatePageLocale();
    EventBus.$on(
      LanguageEvents.LOCALE_CHANGE,
      platformExtension.services.getService('languages').updatePageLocale
    );

    // Load all extension modules
    await platformExtension.services.getService('moduleManager').loadAll();

    // If the tab was opened to a route from an added module, the router will have already navigated
    // to 404 because the router is created before the modules are loaded.
    // We have to re-navigate to the current route so that the router will
    // update the rendered component to match the actual route

    // The current route will have actual current path but old meta.
    // Get the actual route info by resolving it again
    const currentRoute = platformExtension.router.currentRoute;
    const resolved = platformExtension.router.resolve(currentRoute.path);

    if (
      resolved &&
      resolved.route &&
      !PlatformRoutes.find((route) => route.path === resolved.route.path)
    ) {
      // We can tell if the current route is from an extension module simply by checking
      // if it does not match any of the routes of the platform
      await platformExtension.router.replace(resolved);
    }
  } catch (err) {
    console.error('Error on app startup', err);
    const resolved = platformExtension.router.resolve(platformExtension.router.currentRoute.path);
    if (!resolved || !resolved.route.name !== 'applicationError') {
      await platformExtension.router.push({ name: 'applicationError' });
    }
  } finally {
    // In case of start success/error remove global loading state to be able to display
    // the real app / error
    platformExtension.store.commit(`${APPLICATIONS_NAMESPACE}/${SET_LOADING_MODULES}`, {
      loadingState: false,
    });
    EventBus.$emit(PlatformEvents.APP_STARTED);
  }
}

start();
